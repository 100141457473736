import React, { useState, useEffect } from "react";
import styles from "./SubscriptionCheckout.module.scss";
import { usePage } from "@inertiajs/react";

import PrivateAreaUtils from "../../../Utils/PrivateAreaUtils";
import SVG from "../../../Utils/SVG";
import UserAddresses from "../../PrivateArea/UserAddresses/Index";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import SubscriptionCard from "../SubscriptionCard/Index";
import SubscriptionThankYou from "../SubscriptionThankYou/Index";
import SatispayButton from "../SatispayButton/Index";
import NexiButton from "../NexiButton/Index";
import PayPalButton from "../PayPalButton/Index";

const SubscriptionCheckout = ({ subscriptionId }) => {
    const { userLogged, locale } = usePage().props;

    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const modal = document.getElementById("SubscriptionCheckoutModal");

            const handleModalShow = () => setIsModalVisible(true);
            const handleModalHide = () => setIsModalVisible(false);

            modal.addEventListener("shown.bs.modal", handleModalShow);
            modal.addEventListener("hidden.bs.modal", handleModalHide);

            return () => {
                modal.removeEventListener("shown.bs.modal", handleModalShow);
                modal.removeEventListener("hidden.bs.modal", handleModalHide);
            };
        }
    }, []);

    // Caricamento dettagli abbonamento
    const [subscriptionData, setSubscriptionData] = useState(null);

    // Carica le informazioni per la colonna destra delle informaizoni relative all'abbonamento selezionto dall'utente
    useEffect(() => {
        const loadSubscriptionData = async () => {
            const subscriptionData = await PrivateAreaUtils.subscription.get(subscriptionId);
            if (subscriptionData) {
                setSubscriptionData(subscriptionData);
            } else {
                setSubscriptionData(null);
                console.error("Impossibile ottenere i dettagli dell'abbonamento selezionato.");
            }
        };

        if (subscriptionId) {
            loadSubscriptionData();
        }
    }, [subscriptionId]);

    // Gestione fattura
    const [isInvoiceNeeded, setIsInvoiceNeeded] = useState(false);
    const [invoiceErrorMessage, setInvoiceErrorMessage] = useState(null);

    const handleInvoiceChange = async (e) => {
        setIsInvoiceNeeded(e.target.checked);
        if (e.target.checked) {
            await validateInvoiceAddress(selectedAddressId);
        } else {
            setInvoiceErrorMessage(null);
        }
    };

    // Gestione metodi di pagamento
    const paymentMethods =
        subscriptionData && subscriptionData.payment_type_allow ? JSON.parse(subscriptionData.payment_type_allow) : [];

    // Se c'è solo un metodo di pagamento, lo imposto come attivo
    useEffect(() => {
        if (paymentMethods.length === 1) {
            setSelectedPaymentMethod(paymentMethods[0]);
        }
    }, [paymentMethods]);

    // Dettagli descrittivi metodi pagamento
    const paymentMethodDetails = {
        SATISPAY_SUBSCRIPTION: {
            label: "Abbonati con Satispay",
            icon: SVG.satispay,
            successMessage: "Potrai accedere immediatamente a tutti i contenuti esclusivi riservati agli abbonati.",
        },
        PAYPAL_SUBSCRIPTION: {
            label: "Abbonati con PayPal",
            icon: SVG.paypal,
            successMessage: "Potrai accedere immediatamente a tutti i contenuti esclusivi riservati agli abbonati.",
        },
        PAYPAL_CREDITCARD_SUBSCRIPTION: {
            label: "Abbonati con Carta di Credito tramite PayPal",
            icon: SVG.paypalCreditCard,
            successMessage: "Potrai accedere immediatamente a tutti i contenuti esclusivi riservati agli abbonati.",
        },
        NEXI_SUBSCRIPTION: {
            label: "Abbonati con Carta di Credito tramite Nexi",
            icon: SVG.nexi,
            successMessage: "Potrai accedere immediatamente a tutti i contenuti esclusivi riservati agli abbonati.",
        },
        RID: {
            label: "Abbonati tramite RID",
            icon: SVG.rid,
            successMessage:
                "Abbiamo inviato al tuo indirizzo e-mail [email] le istruzioni per procedere all'attivazione del tuo abbonamento.",
        },
    };

    // Stati per la gestione di metodo di pagamento - id indirizzo
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressId, setSelectedAddressId] = useState(null);

    // Handler per la selezione del metodo di pagamento
    const handlePaymentMethodChange = (methodKey) => {
        setSelectedPaymentMethod(methodKey);
    };

    // Handler per la selezione dell'indirizzo
    const handleAddressSelection = async (addressId, address) => {
        setSelectedAddressId(addressId);
        setSelectedAddress(address);
        if (isInvoiceNeeded) {
            await validateInvoiceAddress(addressId);
        }
    };

    const validateInvoiceAddress = async (addressId) => {
        if (!addressId) {
            setInvoiceErrorMessage("Seleziona un indirizzo per la fatturazione.");
            return false;
        }
        const address = await PrivateAreaUtils.address.getByID(addressId);
        if (address && !address.piva) {
            setInvoiceErrorMessage("Seleziona o aggiungi un indirizzo con la partita IVA per la fatturazione.");
            return false;
        }
        setInvoiceErrorMessage(null);
        return true;
    };

    // Condizione per abilitare il bottone di checkout: devono essere selezionati un metodo di pagamento e un indirizzo valido per la fatturazione, se fatturazione richiesta
    const isCheckoutButtonEnabled = Boolean(
        selectedPaymentMethod && selectedAddressId && (!isInvoiceNeeded || !invoiceErrorMessage)
    );
    /* console.log("selectedPaymentMethod", selectedPaymentMethod);
	console.log("selectedAddressId", selectedAddressId);
	console.log("isCheckoutButtonEnabled", isCheckoutButtonEnabled); */

    // Gestione effettiva del pagamento (Satispay ha la gestione a parte dedicata dentro il componente <SatispayButton>, PayPal e Nexi idem)
    // -- Stati per la gestione dei messaggi di errore o stato di successo del pagamento
    const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    // -- Submit del pagamento (non viene praticamente mai chiamata questa, alla fine viene usata solo per il RID)
    const handleSubmit = async () => {
        let paymentMethod = selectedPaymentMethod.toLowerCase();
        try {
            let subscriptionExtraData = {
                plan_id: subscriptionData.id,
                locale: locale,
                email: userLogged.email,
                name: userLogged.name,
                private_address_id: selectedAddressId,
                is_invoice_required: isInvoiceNeeded,
            };

            /*if (isInvoiceNeeded) {
				subscriptionExtraData.email = selectedAddress.email;
				subscriptionExtraData.name = selectedAddress.name;
			}*/

            const response = await PrivateAreaUtils.subscription.create(
                subscriptionId,
                paymentMethod,
                subscriptionExtraData
            );

            //console.log("RESPONSE PAYMENT handleSubmit()", response);

            if (response) {
                if (response.result === true) {
                    //console.log("RESPONSE PAYMENT handleSubmit() è andata BENE!");
                    // PROBABILMENTE ANDRA RIVISTO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                    setPaymentSuccess(true);
                } else {
                    console.error("RESPONSE PAYMENT handleSubmit() è andata MALE!");
                    if (response?.message) {
                        setPaymentErrorMessage(response?.message);
                    } else {
                        setPaymentErrorMessage(
                            "Si è verificato un errore imprevisto durante l'esecuzione del pagamento. Per favore, riprova più tardi."
                        );
                    }
                }
            }
        } catch (error) {
            setPaymentErrorMessage(
                "Si è verificato un errore imprevisto durante l'esecuzione del pagamento. Per favore, riprova più tardi."
            );
        }
    };

    // Gestione Satispay success ed error
    const handleSatispaySuccess = () => {
        // console.log("Il pagamento Satispay è stato completato con successo.");
        setPaymentSuccess(true);
    };

    const handleSatispayError = (error) => {
        console.error("Si è verificato un errore con il pagamento Satispay:", error);
        // Aggiorna l'interfaccia utente con l'errore
        setPaymentErrorMessage(error);
    };

    // Gestione Nexi success ed error
    const handleNexiSuccess = () => {
        // console.log("Il pagamento Nexi è stato completato con successo.");
        setPaymentSuccess(true);
    };

    const handleNexiError = (error) => {
        console.error("Si è verificato un errore con il pagamento Nexi:", error);
        // Aggiorna l'interfaccia utente con l'errore
        setPaymentErrorMessage(error);
    };

    // Gestione PayPal success ed error
    const handlePayPalSuccess = () => {
        // console.log("Il pagamento PayPal è stato completato con successo.");
        setPaymentSuccess(true);
    };

    const handlePayPalError = (error) => {
        console.error("Si è verificato un errore con il pagamento PayPal:", error);
        // Aggiorna l'interfaccia utente con l'errore
        setPaymentErrorMessage(error);
    };

    // Bottone "Paga ora"
    const PaymentButton = () => {
        if (!isCheckoutButtonEnabled) {
            return (
                <button
                    className={`btn btn-primary d-flex justify-content-between mt-4 w-100 ${styles.checkoutButton}`}
                    disabled={true}
                    onClick={handleSubmit}
                >
                    Paga ora
                    <div dangerouslySetInnerHTML={{ __html: SVG.rightArrowWhite }} />
                </button>
            );
        }

        switch (selectedPaymentMethod?.toLowerCase()) {
            case "satispay_subscription":
                return (
                    <SatispayButton
                        subscriptionData={subscriptionData}
                        onSatispaySubscriptionSuccess={handleSatispaySuccess}
                        onSatispaySubscriptionError={handleSatispayError}
                        selectedAddressId={selectedAddressId}
                        isInvoiceNeeded={isInvoiceNeeded}
                    />
                );
            case "nexi_subscription":
                return (
                    <NexiButton
                        subscriptionData={subscriptionData}
                        onNexiSubscriptionSuccess={handleNexiSuccess}
                        onNexiSubscriptionError={handleNexiError}
                        selectedAddressId={selectedAddressId}
                        isInvoiceNeeded={isInvoiceNeeded}
                    />
                );
            case "paypal_subscription":
                return (
                    <PayPalButton
                        subscriptionData={subscriptionData}
                        onPayPalSubscriptionSuccess={handlePayPalSuccess}
                        onPayPalSubscriptionError={handlePayPalError}
                        selectedAddressId={selectedAddressId}
                        isInvoiceNeeded={isInvoiceNeeded}
                    />
                );
            default:
                return (
                    <button
                        className={`btn btn-primary d-flex justify-content-between mt-4 w-100 ${styles.checkoutButton}`}
                        disabled={!isCheckoutButtonEnabled}
                        onClick={handleSubmit}
                    >
                        Paga ora
                        <div dangerouslySetInnerHTML={{ __html: SVG.rightArrowWhite }} />
                    </button>
                );
        }
    };

    return (
        <div
            className="modal"
            id="SubscriptionCheckoutModal"
            tabIndex="-1"
            aria-labelledby="SubscriptionCheckoutModalLabel"
            aria-hidden="true"
            data-bs-backdrop="false"
        >
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                    <div className={`${styles.header} modal-header`}>
                        <div
                            className={`${styles.logo} mx-auto`}
                            dangerouslySetInnerHTML={{
                                __html: SVG.logo,
                            }}
                        />
                    </div>

                    <div className={`${styles.body} modal-body`}>
                        <div className={`${styles.container} container-md pt-3 pb-3`}>
                            <div className={`row`}>
                                {paymentSuccess ? (
                                    <SubscriptionThankYou
                                        subscription={subscriptionData}
                                        paymentMethod={selectedPaymentMethod}
                                        paymentMethodDetails={paymentMethodDetails}
                                        email={isInvoiceNeeded ? selectedAddress.email : userLogged.email}
                                    />
                                ) : (
                                    <>
                                        {/* Colonna sinistra - Dettagli di pagamento: scelta metodo di pagameto, scelta indirizzo di fatturaizone, richiesta fattura */}
                                        <div className={`col-lg-7 col-xxl-6 offset-xxl-1`}>
                                            <div className={`${styles.userDetails}`}>
                                                {/* Titolo */}
                                                <h1 className={`h3`}>Completa l'acquisto del tuo abbonamento</h1>

                                                {/* Scelta indirizzo di fatturazione */}
                                                <h2 className={`h4 mt-4`}>Dati per ricevuta/fattura</h2>
                                                <p className={`mt-3`}>
                                                    Inserisci o seleziona un nuovo indirizzo per procedere con il
                                                    pagamento:
                                                </p>
                                                <UserAddresses
                                                    user={userLogged}
                                                    showActions={false}
                                                    onSelectAddress={handleAddressSelection}
                                                    extraBackdrop={true}
                                                    isCheckoutView={true}
                                                    customCSSClass={styles.addCursor}
                                                    isParentModalVisible={isModalVisible}
                                                />

                                                {/* Richiesta fattura */}
                                                <div className={`mt-4`}>
                                                    <div className={`${styles.invoiceQuestionWrapper}`}>
                                                        <strong className={`d-block`}>Vuoi ricevere la fattura?</strong>
                                                        <div className="form-check form-switch mb-0">
                                                            <input
                                                                className="form-check-input mt-0"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="SubscriptionCheckoutInvoiceCheck"
                                                                checked={isInvoiceNeeded}
                                                                onChange={handleInvoiceChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    {invoiceErrorMessage && (
                                                        <div
                                                            className="alert alert-danger d-flex align-items-center mt-2 rounded-0"
                                                            role="alert"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="flex-shrink-0 me-2"
                                                                viewBox="0 0 16 16"
                                                                role="img"
                                                                aria-label="Errore:"
                                                                style={{
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    fill: "currentColor",
                                                                }}
                                                            >
                                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                            </svg>
                                                            <div>{invoiceErrorMessage}</div>
                                                        </div>
                                                    )}
                                                </div>

                                                {/* Scelta metodo di pagamento */}
                                                <h2 className={`h4 mt-4`}>Scegli come pagare</h2>
                                                <p className={`mt-3`}>
                                                    Seleziona uno dei metodi di pagamento per proseguire:
                                                </p>
                                                {paymentMethods.length === 0 ? (
                                                    <Skeleton
                                                        count={4}
                                                        containerClassName={`mt-2 ${styles.skeletonPaymentCardWrapper}`}
                                                        inline={true}
                                                    />
                                                ) : (
                                                    <ul className={`${styles.paymentsList} mt-2`}>
                                                        {paymentMethods.map((methodKey, index) => {
                                                            const method = paymentMethodDetails[methodKey];
                                                            return (
                                                                <li
                                                                    key={`paymentMethodCard-${index}`}
                                                                    className={`${styles.paymentCard} ${
                                                                        styles[methodKey]
                                                                    } ${
                                                                        selectedPaymentMethod === methodKey ||
                                                                        paymentMethods.length === 1
                                                                            ? styles.active
                                                                            : ""
                                                                    } font-sans`}
                                                                    onClick={() => handlePaymentMethodChange(methodKey)}
                                                                >
                                                                    <input
                                                                        className={`form-check-input mt-0`}
                                                                        type="radio"
                                                                        id={`paymentMethod-${index}`}
                                                                        name="paymentMethod"
                                                                        value={methodKey}
                                                                        defaultChecked={paymentMethods.length === 1}
                                                                        checked={selectedPaymentMethod === methodKey}
                                                                    />
                                                                    <label
                                                                        htmlFor={`paymentMethod-${index}`}
                                                                        className={`${styles.paymentLabel}`}
                                                                    >
                                                                        <span>{method.label}</span>
                                                                        <div
                                                                            className={`${styles.iconWrapper}`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: method.icon,
                                                                            }}
                                                                        />
                                                                    </label>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}

                                                {/* Richiesta Codice Fiscale per ricevuta */}
                                                {/*<p className={`mt-4 d-block`}>
													Inserisci il tuo codice fiscale per ottenere la
													ricevuta:
												</p>
												<div className={`mt-2 ${styles.fiscalCodeWrapper}`}>
													<input
														className={`form-control font-sans`}
														type="text"
														name="codiceFiscale"
														placeholder="Inserisci qui il tuo codice fiscale"
														required
														onChange={handleFiscalCodeChange}
														value={fiscalCode}
													/>
                                                    </div>*/}

                                                {/* Richiesta fattura con elenco indirizzi */}
                                                {/*<div className={`mt-4`}>
													<div
														className={`${styles.invoiceQuestionWrapper}`}
													>
														<strong className={`d-block`}>
															Vuoi ricevere la fattura?
														</strong>
														<div className="form-check form-switch mb-0">
															<input
																className="form-check-input mt-0"
																type="checkbox"
																role="switch"
																id="SubscriptionCheckoutInvoiceCheck"
																checked={isInvoiceNeeded}
																onChange={handleInvoiceChange}
															/>
														</div>
													</div>
													{isInvoiceNeeded && (
														<>
															<p>
																Seleziona o aggiungi i tuoi dettagli
																di fatturazione:
															</p>
															<UserAddresses
																user={userLogged}
																showActions={false}
																onSelectAddress={
																	handleAddressSelection
																}
																extraBackdrop={true}
															/>
														</>
													)}
                                                            </div>*/}

                                                {/* Messaggio info RID */}
                                                {selectedPaymentMethod?.toLowerCase() === "rid" && (
                                                    <div
                                                        className="alert alert-info d-flex align-items-center mt-4 rounded-0"
                                                        role="alert"
                                                    >
                                                        <div>
                                                            Dovrai scaricare e compilare il modulo di autorizzazione di
                                                            addebito ed inviarlo via e-mail a&nbsp;
                                                            <a href="mailto:commerciale@vallesabbianews.it">
                                                                commerciale@vallesabbianews.it
                                                            </a>
                                                            &nbsp;oppure consegnarlo in una delle filiali della Cassa
                                                            Rurale Giudicarie Valsabbia Paganella.
                                                            <br />
                                                            Il costo dell’operazione è a carico di Edizioni Valle
                                                            Sabbia, sarà dovuto solo il costo dell’abbonamento scelto.
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Messaggio di errore */}
                                                {paymentErrorMessage && (
                                                    <div
                                                        className="alert alert-danger d-flex align-items-center mt-4 mb-3 rounded-0"
                                                        role="alert"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="flex-shrink-0 me-2"
                                                            viewBox="0 0 16 16"
                                                            role="img"
                                                            aria-label="Errore:"
                                                            style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                fill: "currentColor",
                                                            }}
                                                        >
                                                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                        </svg>
                                                        <div>{paymentErrorMessage}</div>
                                                    </div>
                                                )}

                                                {/* Bottone submit pagamento + Satispay + Nexi */}
                                                <PaymentButton />
                                            </div>
                                        </div>

                                        {/* Colonna destra - Dettagli pacchetto abbonamento selezionato */}
                                        <div className={`col-lg-5 col-xxl-4`}>
                                            {subscriptionData ? (
                                                <SubscriptionCard {...subscriptionData} isCheckout={true} />
                                            ) : (
                                                <Skeleton containerClassName={`${styles.skeletonSubscriptionCard}`} />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionCheckout;
