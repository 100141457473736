import React from "react";
import Form from "../../Form/Index";
//import styles from "./RegisterModal.module.scss";

const RegisterModal = () => {
    const passwordsAreEqualValidation = (formData, formElements) => {
        let errors = {};

        const password = formData.get("password");
        const password2 = formData.get("password2");

        // console.log("formData passwordsAreEqualValidation", formData);

        // Controlla se le password sono presenti
        if (!password || !password2) {
            if (!password) {
                errors.password = "La password è richiesta.";
            }

            if (!password2) {
                errors.password2 = "La conferma della password è richiesta.";
            }
        }
        // Controlla se le password corrispondono
        else if (password !== password2) {
            errors.password2 = "Le password non corrispondono.";
        }
        // Controllo per la lunghezza della password
        /*else if (password.length < 8 || password2.length < 8) {
            errors.password = 'La password deve contenere almeno 8 caratteri.';
            errors.password2 = 'La password deve contenere almeno 8 caratteri.';
        }*/

        return errors;
    };

    return (
        <div
            className="modal fade"
            id="registerModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="registerModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    {/** Header */}
                    <div className="modal-header">
                        <div>
                            <h1 className="modal-title fs-5" id="registerModalLabel">
                                Registrati a Valle Sabbia News
                            </h1>
                            <p className="font-sans mt-2">
                                Inserisci i tuoi dati per registrarti a ValleSabbiaNews.it, riceverai un’email con le
                                indicazioni per completare la registrazione del tuo account. Se non trovi la mail prova a 
                                controllare nello spam altrimenti contatta l'assistenza.
                            </p>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Chiudi"
                        ></button>
                    </div>

                    {/** Body */}
                    <div className="modal-body">
                        <Form
                            apiUrl={"/api/auth/register"}
                            extraProps={{ id: "formRegistrazione" }}
                            refreshDelay={0}
                            stateReset={false}
                            customValidation={passwordsAreEqualValidation}
                            fieldsHTML={
                                <>
                                    <input type="hidden" name="core_country_id" value={1} />

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="registerModalNome" className="mb-1">
                                                Nome
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Nome"
                                                id="registerModalNome"
                                                required
                                            />
                                        </div>

                                        <div className="form-group col-md-6 mt-3 mt-md-0">
                                            <label htmlFor="registerModalCognome" className="mb-1">
                                                Cognome
                                            </label>
                                            <input
                                                type="text"
                                                name="surname"
                                                className="form-control"
                                                placeholder="Cognome"
                                                id="registerModalCognome"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="registerModalEmail" className="mb-1">
                                            E-Mail
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="E-Mail"
                                            id="registerModalEmail"
                                            required
                                            inputMode="email"
                                        />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="registerModalPassword" className="mb-1">
                                            Password
                                        </label>
                                        <input
                                            aria-describedby="passwordHelp"
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Password"
                                            id="registerModalPassword"
                                            minLength="8"
                                            required
                                        />
                                        <div id="passwordHelp" className="form-text font-sans">
                                            Inserisci una password di almeno 8 caratteri.
                                        </div>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label htmlFor="registerModalPassword2" className="mb-1">
                                            Conferma la password
                                        </label>
                                        <input
                                            type="password"
                                            name="password2"
                                            className="form-control"
                                            placeholder="Conferma la password"
                                            id="registerModalPassword2"
                                            minLength="8"
                                            required
                                        />
                                    </div>

                                    <input type="submit" className="btn btn-primary mt-4" value="Registrati" />
                                </>
                            }
                            onSuccess={(response) => {
                                if (typeof window !== "undefined" && window._iub) {
                                    const subjectId = document.querySelector(
                                        '#formRegistrazione [data-cons-subject="email"]'
                                    ).value;
                                    _iub.cons_instructions.push([
                                        "submit",
                                        {
                                            writeOnLocalStorage: false,
                                            form: {
                                                selector: document.getElementById("formRegistrazione"),
                                            },
                                            consent: {
                                                subject: { id: subjectId },
                                                legal_notices: [
                                                    { identifier: "privacy_policy" },
                                                    { identifier: "cookie_policy" },
                                                ],
                                            },
                                        },
                                        {
                                            success: function (response) {
                                                // console.log(response);
                                            },
                                            error: function (response) {
                                                // console.log(response);
                                            },
                                        },
                                    ]);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterModal;
